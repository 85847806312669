import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import image from "../../assets/service.svg";
import "./Enquiry.css";
import {
  FaWhatsapp,
  FaFacebook,
  FaEnvelope,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaTimes,
} from "react-icons/fa";
import { BACKEND_URL } from "../../utils/constant";

const EnquiryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const location = useLocation();
  const { id } = location.state || {}; // Retrieve data passed through navigate

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    body: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationError, setValidationError] = useState("");
  const [showIcons, setShowIcons] = useState(false); // State to toggle the social media icons

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      if (!/^\d*$/.test(value)) {
        setValidationError("Mobile number should contain only digits.");
        return;
      } else if (value.length > 10) {
        setValidationError("Mobile number cannot exceed 10 digits.");
        return;
      }
      setTimeout(() => {
        setValidationError("");
      }, 3000);
    }

    if (name === "name") {
      const nameRegex = /^[a-zA-Z\s]*$/;
      if (!nameRegex.test(value)) {
        setValidationError("Name can only contain alphabetic characters.");
        setTimeout(() => {
          setValidationError("");
        }, 3000);
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
    setValidationError(""); // Clear validation error
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Fetch property details to include leaseLifeId and saleLifeId
      const propertyResponse = await axios.get(
        `${BACKEND_URL}/api/properties/${id}`
      );
  
      let { leaseLifeId, saleLifeId } = propertyResponse.data;
  
      // Set saleLifeId to 0 if it is null or undefined
      saleLifeId = saleLifeId || 0;
  
      // Prepare payload and conditionally add leaseLifeId if it’s greater than 0
      const payload = {
        propertyId: id,
        saleLifeId,
        ...formData,
        source: "Website", // Additional fields if required by VaultRE
        categories: [{ id: 0 }],
        metaData: [{ key: "additionalInfo", value: "Some extra info" }],
      };
  
      // Only include leaseLifeId if it's greater than 0
      if (leaseLifeId && leaseLifeId > 0) {
        payload.leaseLifeId = leaseLifeId;
      }
  
      console.log("Submitting payload:", payload);
  
      const response = await axios.post(`${BACKEND_URL}/api/enquiries`, payload);

      if (response.status === 200) {
        setSuccessMessage("Form submitted successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          subject: "",
          body: "",
        });
      }
    } catch (error) {
      const serverMessage = error.response?.data?.message || "Server error.";
      const details = error.response?.data || {};
      
      setErrorMessage(`Failed to submit the form: ${serverMessage}`);
      console.error("Error submitting form:", error);
      console.error("Detailed error response:", details);
    }
  };

  const handleCancel = () => {
    setFormData({
      name: "",
      email: "",
      mobile: "",
      subject: "",
      body: "",
    });
  };

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  const shareUrl = "https://propertybuyersaustraliagroup.com.au/landing-page/";

  return (
    <div className="market-property-container">
      <div className="market-property-form">
        <div className="form-content">
          <div className="form-image">
            <img src={image} alt="Property" />
          </div>

          <div className="form-fields">
            <h2>Enquire Property</h2>
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            {errorMessage && (
              <p className="error-message" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}
            {validationError && (
              <p className="error-message" style={{ color: "red" }}>
                {validationError}
              </p>
            )}

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  maxLength="10"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="body"
                  placeholder="Body"
                  value={formData.body}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="form-buttons">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="additional-buttons">
          <div
            className="action-btn-container"
            style={{ position: "relative", display: "inline-block" }}
          >
            <button className="action-btn" onClick={toggleIcons}>
              Refer Us
            </button>

            {showIcons && (
              <div className="social-icons-container">
                <FaWhatsapp
                  className="social-icon fa-whatsapp"
                  onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(shareUrl)}`, "_blank")}
                />
                <FaFacebook
                  className="social-icon fa-facebook"
                  onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, "_blank")}
                />
                <FaEnvelope
                  className="social-icon fa-envelope"
                  onClick={() => window.open(`mailto:?subject=Check%20this%20out&body=${encodeURIComponent(shareUrl)}`, "_blank")}
                />
                <FaInstagram
                  className="social-icon fa-instagram"
                  onClick={() => alert("Instagram sharing requires the app, share the link manually.")}
                />
                <FaLinkedin
                  className="social-icon fa-linkedin"
                  onClick={() => window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareUrl)}&title=Check%20this%20out!`, "_blank")}
                />
                <FaTwitter
                  className="social-icon fa-twitter"
                  onClick={() => window.open(`https://twitter.com/share?url=${encodeURIComponent(shareUrl)}&text=Check%20this%20out!`, "_blank")}
                />
                <FaTimes
                  className="social-icon close-icon"
                  onClick={toggleIcons}
                />
              </div>
            )}
          </div>
          <div
            className="action-btn-container"
            style={{ position: "relative", display: "inline-block" }}
          >
            <div className="action-btn">
              <Link to="/social-media-reach">Follow Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryPage;
